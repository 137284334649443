import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import { store, key } from './store';
import '@/assets/scss/cp.scss';
import 'bootstrap';
import VueChartkick from 'vue-chartkick';
import 'chartkick/chart.js';
import { sentry } from './utils';

const app = createApp(App).use(store, key).use(VueChartkick).use(router);

const useSentry = process.env.VUE_APP_SENTRY_ACTIVE === 'true';
sentry.init(useSentry, app, router);

app.mount('#app');
