import {
  ConsentStatus,
  DataRequest,
  RequestStatus,
  DocumentType,
  TinAvailability,
  File,
  DataRequestData,
  DataRequestConfig,
  DataRequestType,
  IndividualsDataRecord,
  Individual,
  IndividualsDataFinancialInfo,
  DataRequestIndFinConfig
} from '@cito/cp-data';
import { DataRequestService, FileService } from '@/services';

export class DataRequestHelper {
  private dataRequestService = new DataRequestService();
  private fileService = new FileService();
  private dataRequest: DataRequest;
  private loading = false;

  constructor(dataRequest: DataRequest) {
    this.dataRequest = dataRequest;
  }

  // Update
  public get isLoading(): boolean {
    return this.loading;
  }

  public async save(): Promise<void> {
    this.loading = true;
    await this.dataRequestService.updateDataRequest(this.dataRequest);
    this.loading = false;
  }

  public async complete(): Promise<void> {
    this.loading = true;
    await this.dataRequestService.updateDataRequest(this.dataRequest);
    await this.dataRequestService.completeDataRequest();
    this.loading = false;
  }

  public async submit(): Promise<void> {
    if (this.dataRequest) {
      this.dataRequest.status = RequestStatus.COMPLETE;
      this.complete();
    }
  }

  public async uploadDocument(
    newDocument: File,
    type: DocumentType | string,
    frequency?: string
  ): Promise<void> {
    if (this.dataRequest) {
      this.loading = true;
      const documentId = await this.fileService.uploadFile(newDocument);
      this.loading = false;

      if (documentId) {
        if (!this.dataRequest.data) {
          this.dataRequest.data = {} as DataRequestData;
        }
        const data = this.dataRequest.data as DataRequestData;
        if (!data.docs) {
          data.docs = { dataDeclined: false, documents: [] };
        }

        data.docs.documents.push({
          id: documentId,
          filename: newDocument.fileName,
          type: type,
          isAdditional: newDocument.isAdditional,
          frequency: frequency
        });

        await this.save();
      }
    }
  }

  public async removeDocument(documentId: string): Promise<void> {
    if (!this.dataRequest.data) {
      this.dataRequest.data = {} as DataRequestData;
    }
    const data = this.dataRequest.data as DataRequestData;

    if (!data.docs) {
      data.docs = { dataDeclined: false, documents: [] };
    }

    for (let i = 0; i < data.docs.documents.length; i++) {
      if (data.docs.documents[i].id === documentId) {
        data.docs.documents.splice(i, 1);
        await this.save();
        break;
      }
    }
  }

  public async addAdditonalDoc(documentName: string): Promise<void> {
    if (!this.dataRequest.data) {
      this.dataRequest.data = {} as DataRequestData;
    }

    if (this.dataRequest.data && !this.dataRequest.data.otherUploadedDocs) {
      this.dataRequest.data.otherUploadedDocs = [];
    }

    if (this.dataRequest.data.otherUploadedDocs) {
      this.dataRequest.data.otherUploadedDocs.push(documentName);
    }

    await this.save();
  }

  public async updateAdditionalDocs(
    documentName: DocumentType,
    index: number,
    oldDoc: DocumentType[]
  ): Promise<void> {
    const otherDocs = this.dataRequest.data?.otherUploadedDocs;
    const oldIndexDocument = oldDoc[index];
    if (otherDocs) {
      otherDocs[index] = documentName;

      const data = this.dataRequest.data as DataRequestData;

      if (data.docs?.documents.length) {
        for (let i = 0; i < data.docs.documents.length; i++) {
          if (
            data.docs.documents[i].isAdditional &&
            data.docs.documents[i].type &&
            data.docs.documents[i].type == oldIndexDocument
          ) {
            data.docs.documents[i].type = documentName as DocumentType;
          }
        }
      }
    }

    await this.save();
  }

  public get otherDocs(): any[] {
    const ret = this.dataRequest.data?.otherUploadedDocs;
    return ret || [];
  }

  public getIndividualsDataRecord(): IndividualsDataRecord {
    const ret: IndividualsDataRecord = {
      individual: {} as Individual,
      financialInfo: {
        summary: {
          assets: 0,
          liabilities: 0,
          netPosition: 0,
          income: 0,
          expenses: 0,
          netCashflow: 0
        },
        personal: {},
        assets: [],
        liabilities: [],
        incomes: [],
        expenses: []
      }
    };

    if (this.dataRequest) {
      ret.financialInfo = this.dataRequest.data as IndividualsDataFinancialInfo;

      const dataRequest = this.dataRequest.request as DataRequestIndFinConfig;
      ret.individual.role = dataRequest.individualRole;
      if (dataRequest.individualName) {
        ret.individual.name = dataRequest.individualName;
      }
    }

    return ret;
  }

  // Requirement logic
  public get isConsentRequired(): boolean {
    return true;
  }

  public get isTaxResidencyRequired(): boolean {
    return (this.dataRequest.request as DataRequestConfig)?.taxResidency ? true : false;
  }

  public get isContactsRequired(): boolean {
    if (!(this.dataRequest.request as DataRequestConfig)?.confirmContacts) {
      return false;
    }
    const data = this.dataRequest?.data as DataRequestData | undefined;
    if (!data?.individuals) {
      return false;
    }
    if (data.individuals.length <= 0) {
      return false;
    }
    return true;
  }

  public get isAccountingDataRequired(): boolean {
    return (this.dataRequest.request as DataRequestConfig)?.accountingDataConsent ? true : false;
  }

  public get isBankDataRequired(): boolean {
    return (this.dataRequest.request as DataRequestConfig)?.bankDataConsent ? true : false;
  }

  public get isDocUploadRequired(): boolean {
    if ((this.dataRequest.request as DataRequestConfig)?.docsRequired) {
      for (const key of Object.keys((this.dataRequest.request as DataRequestConfig).docsRequired)) {
        if ((this.dataRequest.request as DataRequestConfig)?.docsRequired[key]) {
          return true;
        }
      }
    }
    return false;
  }

  public get isFinancialInfoRequired(): boolean {
    const request = this.dataRequest.request as any;
    return request.financialInfo ? true : false;
  }

  // Completion logic
  public get isConsentComplete(): boolean {
    if (!this.isConsentRequired) {
      return true;
    }

    return (this.dataRequest?.data as DataRequestData)?.consentProvided ? true : false;
  }

  public get isTaxResidencyComplete(): boolean {
    if (!this.isTaxResidencyRequired) {
      return true;
    }

    const data = this.dataRequest?.data as DataRequestData | undefined;

    if (!data?.taxResidency) {
      return false;
    }
    const base = data?.taxResidency;
    if (base.q1 === undefined) {
      return false;
    }

    if (base.q2 === undefined) {
      return false;
    }
    if (base.q2 && !base.ein) {
      return false;
    }

    if (base.q3 === undefined) {
      return false;
    }
    if (base.q3 && !base.giin) {
      return false;
    }
    if (base.q3 && !base.fatcaStatus) {
      return false;
    }

    if (base.q4 === undefined) {
      return false;
    }
    if (base.q4 && !base.country) {
      return false;
    }
    if (base.q4 && !base.tinAvailability) {
      return false;
    }
    if (base.q4 && base.tinAvailability === TinAvailability.HAVE_TIN && !base.tin) {
      return false;
    }
    if (
      base.q4 &&
      base.tinAvailability === TinAvailability.UNABLE_TO_OBTAIN &&
      !base.noTinExplanation
    ) {
      return false;
    }

    return true;
  }

  public get isContactsComplete(): boolean {
    if (!this.isContactsRequired) {
      return true;
    }

    const data = this.dataRequest?.data as DataRequestData | undefined;

    if (!data?.individuals) {
      return true;
    }
    const base = data?.individuals;
    for (const individual of base) {
      if (!this.isMobileValid(individual.mobile)) {
        return false;
      }
      if (!this.isEmailValid(individual.email)) {
        return false;
      }
    }
    return true;
  }

  public get isAccountingDataComplete(): boolean {
    if (!this.isAccountingDataRequired) {
      return true;
    }

    const data = this.dataRequest?.data as DataRequestData | undefined;

    if (!data?.accountingConsent) {
      return false;
    }
    if (data.accountingConsent.dataDeclined) {
      return true;
    }
    if (data.accountingConsent.consentStatus !== ConsentStatus.APPROVED) {
      return false;
    }

    return true;
  }

  public get isBankDataComplete(): boolean {
    if (!this.isBankDataRequired) {
      return true;
    }

    const data = this.dataRequest?.data as DataRequestData | undefined;

    if (!data?.bankConsent) {
      return false;
    }
    if (data.bankConsent.dataDeclined) {
      return true;
    }
    if (data.bankConsent.consentStatus !== ConsentStatus.APPROVED) {
      return false;
    }

    return true;
  }

  public get isDocUploadComplete(): boolean {
    if (!this.isDocUploadRequired) {
      return true;
    }
    if ((this.dataRequest?.data as DataRequestData)?.docs?.dataDeclined) {
      return true;
    }
    if (this.hasRequestedDocSubmitted.length > 0) {
      return false;
    }
    return true;
  }

  public get isGeneral(): boolean {
    return this.dataRequest.type !== DataRequestType.IND_FINANCIAL_DATA;
  }

  public get isComplete(): boolean {
    return (
      this.isConsentComplete &&
      this.isTaxResidencyComplete &&
      this.isContactsComplete &&
      this.isAccountingDataComplete &&
      this.isBankDataComplete &&
      this.isDocUploadComplete
    );
  }

  public get isDataRequestSubmitted(): boolean {
    if (this.dataRequest?.status === RequestStatus.COMPLETE) {
      return true;
    }
    if (this.dataRequest?.status === RequestStatus.NOT_SENT) {
      return true;
    }
    return false;
  }

  public get isDataRequestCancelled(): boolean {
    if (this.dataRequest?.status === RequestStatus.CANCELLED) {
      return true;
    }
    return false;
  }

  public get hasRequestedDocSubmitted(): DocumentType[] {
    if (!this.isDocUploadRequired) {
      return [];
    }

    const ret: DocumentType[] = [];

    const request = this.dataRequest?.request as DataRequestConfig | undefined;
    const data = this.dataRequest?.data as DataRequestData | undefined;

    if (request?.docsRequired) {
      for (const key of Object.keys(request.docsRequired)) {
        if (request?.docsRequired[key]) {
          if (data?.docs?.documents) {
            let found = false;
            for (const document of data?.docs?.documents) {
              if (document.type === key) {
                found = true;
                break;
              }
            }
            if (!found) {
              ret.push(key as DocumentType);
            }
          }
        }
      }
    }
    return ret;
  }

  public get documentsRequired(): any {
    if (!this.isDocUploadRequired) {
      return [];
    }

    const ret: any = {};

    const request = this.dataRequest?.request as DataRequestConfig | undefined;

    if (request?.docsRequired) {
      for (const key of Object.entries(request.docsRequired)) {
        if (!ret[key[0]]) {
          ret[key[0]] = key[1];
        }
      }
    }

    return ret;
  }

  // Additional helper functions
  public isEmailValid(email: string): boolean {
    if (!email || email === '') {
      return false;
    }
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  public isMobileValid(mobile: string): boolean {
    if (!mobile || mobile === '') {
      return false;
    }
    const re =
      /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
    return re.test(mobile);
  }
}
