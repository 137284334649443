import * as Axios from 'axios';
import { store } from '@/store';
import { router } from '@/router';

export default class BaseService {
  protected async makeSecureCall(config: Axios.AxiosRequestConfig): Promise<Axios.AxiosResponse> {
    if (store.state.auth.loading) {
      await store.state.auth.loadingPromise();
    }

    try {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers['Authorization'] = store.state.auth.token;

      return await Axios.default(config);
    } catch (err: any) {
      if (err.response?.status === 401) {
        router.push({ path: `/${this.parseIdFromURI()}` });
      } else if (err.response?.status === 500) {
        return err.response;
      }
      throw err;
    }
  }

  public parseIdFromURI(): string {
    try {
      const parser = document.createElement('a');
      parser.href = window.location.href;

      const path = parser.pathname;
      return path.split('/')[1];
    } catch (err: any) {
      return '';
    }
  }
}
