import * as Axios from 'axios';
import { store } from '@/store';
import BaseService from './BaseService';
import {
  Challenge,
  OtpChallenge,
  ChallengeTypes,
  DataRequest,
  DataRequestToken,
  RequestLoginRequest,
  RequestLoginResponse,
  ValidateTokenRequest,
  ValidateRequestTokenResponse,
  DataRequestType
} from '@cito/cp-data';

export class AuthService extends BaseService {
  public async loadTheme(requestId: string): Promise<string> {
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'get',
      url: `${store.state.apiBase}/auth/request/theme/${requestId}`
    };

    try {
      const res: Axios.AxiosResponse = await Axios.default(apiConfig);
      return res.data.data as string;
    } catch (err) {
      return 'default';
    }
  }

  public async loadType(requestId: string): Promise<DataRequestType | undefined> {
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'get',
      url: `${store.state.apiBase}/auth/request/type/${requestId}`
    };

    try {
      const res: Axios.AxiosResponse = await Axios.default(apiConfig);
      return res.data.data as DataRequestType | undefined;
    } catch (err) {
      return;
    }
  }

  public async login(email: string, requestId: string): Promise<Challenge | null> {
    const req: RequestLoginRequest = {
      data: {
        email: email,
        requestId: requestId
      }
    };

    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'post',
      url: `${store.state.apiBase}/auth/request/login`,
      data: req
    };

    try {
      const res: Axios.AxiosResponse = await Axios.default(apiConfig);

      const response: RequestLoginResponse = res.data as RequestLoginResponse;

      if (!response.meta || !response.meta.challenge) {
        return null;
      }

      return response.meta.challenge;
    } catch (err: any) {
      if (err.response?.status === 401) {
        return null;
      }
      throw err;
    }
  }

  public async loginWithChallenge(
    email: string,
    requestId: string,
    otp: string,
    challengeId: string
  ): Promise<DataRequestToken | null> {
    const req: RequestLoginRequest = {
      data: {
        email: email,
        requestId: requestId
      },
      meta: {
        challenge: {
          challengeId: challengeId,
          type: ChallengeTypes.OTP,
          otp: otp
        } as OtpChallenge
      }
    };

    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'post',
      url: `${store.state.apiBase}/auth/request/login`,
      data: req
    };

    try {
      const res: Axios.AxiosResponse = await Axios.default(apiConfig);

      const response: RequestLoginResponse = res.data as RequestLoginResponse;

      if (!response.data.dataRequest) {
        return null;
      }
      if (!response.data.token) {
        return null;
      }

      return response.data;
    } catch (err: any) {
      if (err.response?.status === 401) {
        return null;
      }
      throw err;
    }
  }

  public async validate(token: string, requestId: string): Promise<DataRequest | null> {
    const req: ValidateTokenRequest = {
      data: {
        token: token
      }
    };

    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'post',
      url: `${store.state.apiBase}/auth/request/token`,
      data: req
    };

    try {
      const res: Axios.AxiosResponse = await Axios.default(apiConfig);

      const response: ValidateRequestTokenResponse = res.data as ValidateRequestTokenResponse;

      if (response.data.id !== requestId) {
        return null;
      }

      return response.data;
    } catch (err: any) {
      if (err.response?.status === 401) {
        return null;
      }
      throw err;
    }
  }
}
