<template>
  <router-view name="header"></router-view>
  <router-view class="nav-space fill-height" />
  <router-view name="footer"></router-view>
</template>

<style scoped lang="scss">
.nav-space {
  margin-top: 3.5rem;
}
.fill-height {
  flex-grow: 1;
  width: 100%;
}
</style>
