import * as Axios from 'axios';
import BaseService from './BaseService';
import { store } from '@/store';
import { CreateFileUploadRequest, CreateFileUploadResponse, File } from '@cito/cp-data';

export class FileService extends BaseService {
  public async uploadFile(fileData: File): Promise<string> {
    const req: CreateFileUploadRequest = {
      data: fileData
    };

    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'post',
      url: `${store.state.apiBase}/request/files`,
      data: req
    };
    const res: Axios.AxiosResponse = await this.makeSecureCall(apiConfig);
    const response = res.data as CreateFileUploadResponse;

    return response.data.fileId;
  }

  public async getFile(fileId: string): Promise<any> {
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'get',
      url: `${store.state.apiBase}/request/files/${fileId}`
    };

    const res: Axios.AxiosResponse = await this.makeSecureCall(apiConfig);

    return res.data;
  }
}
