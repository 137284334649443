import * as Axios from 'axios';
import BaseService from './BaseService';
import { store } from '@/store';
import { Deal, GetDealResponse } from '@cito/cp-data';

export class DealService extends BaseService {
  public async getDeal(id: string): Promise<Deal> {
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'get',
      url: `${store.state.apiBase}/request/deals/${id}`
    };

    const res: Axios.AxiosResponse = await this.makeSecureCall(apiConfig);

    const response: GetDealResponse = res.data as GetDealResponse;

    return response.data;
  }
}
