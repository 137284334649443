import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalized,
  NavigationGuardNext
} from 'vue-router';
import AppHeader from '@/components/navigation/AppHeader.vue';
import AppFooter from '@/components/navigation/AppFooter.vue';
import { store } from '@/store';
import { DataRequestHelper } from '@/domain/helpers';

const guardSecure = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (store.state.auth.loading) {
    await store.state.auth.loadingPromise();
  }
  if (store.state.auth.isAuthenticated) {
    if (store.state.auth.dataRequest) {
      const helper = new DataRequestHelper(store.state.auth.dataRequest);
      if (helper.isDataRequestCancelled) {
        next(`/${to.params.requestId}/cancelled`);
      } else {
        next();
      }
    }
  } else {
    next(`/${to.params.requestId}`);
  }
};

const guardSkipLogin = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  await store.state.auth.loadingPromise();
  if (store.state.auth.isAuthenticated && store.state.auth.dataRequest) {
    const helper = new DataRequestHelper(store.state.auth.dataRequest);

    if (helper.isGeneral) {
      if (helper.isDataRequestSubmitted) {
        next(`/${to.params.requestId}/submitted`);
      } else if (helper.isDataRequestCancelled) {
        next(`/${to.params.requestId}/cancelled`);
      } else if (
        !helper.isConsentComplete ||
        !helper.isTaxResidencyComplete ||
        !helper.isContactsComplete
      ) {
        next(`/${to.params.requestId}/step2`);
      } else if (
        !helper.isBankDataComplete ||
        !helper.isAccountingDataComplete ||
        !helper.isDocUploadComplete
      ) {
        next(`/${to.params.requestId}/step3`);
      } else {
        next(`/${to.params.requestId}/finish`);
      }
    } else {
      if (helper.isDataRequestSubmitted) {
        next(`/${to.params.requestId}/submitted`);
      } else if (helper.isDataRequestCancelled) {
        next(`/${to.params.requestId}/cancelled`);
      } else {
        if (helper.isFinancialInfoRequired) {
          next(`/${to.params.requestId}/individual`);
        } else {
          next(`/${to.params.requestId}/individual/docs`);
        }
      }
    }
  } else {
    next();
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:requestId',
    name: 'Login',
    beforeEnter: guardSkipLogin,
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/:requestId/individual',
    name: 'Individual',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "individual" */ '../views/Individual.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/:requestId/individual/docs',
    name: 'IndividualDocuments',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () =>
        import(/* webpackChunkName: "individualDocuments" */ '../views/IndividualDocuments.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/:requestId/individual/finish',
    name: 'IndividualFinish',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () =>
        import(/* webpackChunkName: "individualfinish" */ '../views/IndividualFinish.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/:requestId/step2',
    name: 'Step2',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "step2" */ '../views/Step2.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/:requestId/step3',
    name: 'Step3',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "step3" */ '../views/Step3.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/:requestId/docs',
    name: 'Documents',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "documents" */ '../views/Documents.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/:requestId/finish',
    name: 'Finish',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "finish" */ '../views/Finish.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/:requestId/submitted',
    name: 'Submitted',
    beforeEnter: guardSecure,
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "submitted" */ '../views/Submitted.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/:requestId/cancelled',
    name: 'Cancelled',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "submitted" */ '../views/Cancelled.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/:requestId/bankconsent',
    name: 'BankConsent',
    beforeEnter: guardSecure,
    components: {
      default: () => import(/* webpackChunkName: "bankConsent" */ '../views/BankConsent.vue')
    }
  },
  {
    path: '/:requestId/fakeLaunch',
    name: 'FakeLaunch',
    beforeEnter: guardSecure,
    components: {
      default: () => import(/* webpackChunkName: "fakeLaunch" */ '../views/FakeLaunch.vue')
    }
  },
  {
    path: '/:requestId/fakeLaunch/:param',
    name: 'FakeLaunchWithParam',
    beforeEnter: guardSecure,
    components: {
      default: () => import(/* webpackChunkName: "fakeLaunch" */ '../views/FakeLaunch.vue')
    }
  }
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  }
});
