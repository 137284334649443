<template>
  <nav
    class="navbar navbar-expand-lg fixed-top navbar-light bg-nav-bar"
    :style="`background-color:${backgroundColor}`"
  >
    <div class="container-fluid">
      <div v-if="logo" class="navbar-brand ms-5 align-items-center d-flex my-0 py-0 local-logo">
        <img
          ref="imageRef"
          :src="logo === 'default' ? '/images/logo.png' : logo"
          class="image"
          @error="setImage()"
        />
      </div>
      <div
        v-else-if="theme"
        class="navbar-brand ms-5 align-items-center d-flex my-0 py-0 theme-logo"
      >
        <img class="small-logo" />
      </div>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </nav>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  setup() {
    const imageRef = ref();

    const setImage = () => {
      if (imageRef.value) {
        imageRef.value.src = '/images/logo.png';
      }
    };

    const logo = computed(() => {
      return localStorage.getItem('image');
    });

    const theme = computed(() => {
      return localStorage.getItem('theme');
    });

    const backgroundColor = computed(() => {
      return localStorage.getItem('color');
    });

    return {
      backgroundColor,
      theme,
      logo,
      imageRef,
      setImage
    };
  }
});
</script>
<style scoped lang="scss">
.theme-logo {
  max-height: 40px;
  min-height: 40px;
}

.local-logo {
  height: 40px;
  width: 6%;
  overflow: hidden;
}

.image {
  height: 40px;
  width: 100px;
  object-fit: contain;
}
</style>
