import * as Axios from 'axios';
import { store } from '@/store';
import BaseService from './BaseService';
import { GoogleAddressSuggestion } from '@cito/cp-data';

export class PropertyLookupService extends BaseService {
  private static instance: PropertyLookupService;

  constructor() {
    super();
    if (PropertyLookupService.instance) {
      return PropertyLookupService.instance;
    }

    PropertyLookupService.instance = this;
  }

  public async getSuggestions(query: string): Promise<{ placeId: string; description: string }[]> {
    //GoogleSuggestionResult
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'get',
      url: `${store.state.apiBase}/request/data/google/suggestions`,
      params: {
        query: query
      }
    };

    const res: Axios.AxiosResponse = await this.makeSecureCall(apiConfig);

    if (res.status === 200 && res.data?.data) {
      return res.data.data as GoogleAddressSuggestion[];
    }
    return [];
  }

  public async getGoogleAddress(query: string): Promise<any> {
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'get',
      url: `${store.state.apiBase}/request/data/google/placeInfo/${query}`
    };

    const res: Axios.AxiosResponse = await this.makeSecureCall(apiConfig);

    if (res.status === 200 && res.data.data) {
      return res.data.data;
    }
  }
}
