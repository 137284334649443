import * as Axios from 'axios';
import { store } from '@/store';
import BaseService from './BaseService';

export class IllionService extends BaseService {
  public async saveConsent(): Promise<void> {
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'post',
      url: `${store.state.apiBase}/request/data/illion/consent`
    };

    await this.makeSecureCall(apiConfig);

    return;
  }
}
