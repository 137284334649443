import { InjectionKey } from 'vue';
import { createStore, Store, useStore as baseUseStore } from 'vuex';
import { Auth } from '@/domain/security';

// Cito+ specific state typings
export interface State {
  apiBase: string;
  returnBase: string;
  illionCode: string;
  illionLoader: string;
  customerData: any;
  auth: Auth;
}

// Define type injection key
export const key: InjectionKey<Store<State>> = Symbol();

// Define our own `useStore` composition function
export function useStore(): Store<State> {
  return baseUseStore(key);
}

// Environment configurarion
import { envs } from '../../env_config.json';

const env = process.env.VUE_APP_ENV || 'dev';

console.log(`environment: ${env}`);

// Create the store
export const store = createStore<State>({
  state: {
    apiBase: (envs as any)[env].apiBase,
    returnBase: (envs as any)[env].returnBase,
    illionCode: (envs as any)[env].illionCode,
    illionLoader: (envs as any)[env].illionLoader,
    customerData: {},
    auth: new Auth()
  },
  mutations: {},
  actions: {},
  modules: {}
});

store.state.auth.loadFromStore();
