import * as Axios from 'axios';
import BaseService from './BaseService';
import { store } from '@/store';
import { DataRequest, UpdateDataRequestRequest } from '@cito/cp-data';

export class DataRequestService extends BaseService {
  public async updateDataRequest(dataRequest: DataRequest): Promise<void> {
    const req: UpdateDataRequestRequest = {
      data: dataRequest
    };

    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'put',
      url: `${store.state.apiBase}/request/requests`,
      data: req
    };

    await this.makeSecureCall(apiConfig);

    return;
  }

  public async completeDataRequest(): Promise<void> {
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'put',
      url: `${store.state.apiBase}/request/requests/complete`
    };

    await this.makeSecureCall(apiConfig);

    return;
  }
}
