import * as Axios from 'axios';
import { store } from '@/store';
import BaseService from './BaseService';
import { CodatConsentResult } from '@cito/cp-data';

export class CodatService extends BaseService {
  public async initiateConsent(name: string): Promise<CodatConsentResult> {
    const apiConfig: Axios.AxiosRequestConfig = {
      method: 'post',
      url: `${store.state.apiBase}/request/data/codat/consent`,
      data: {
        name: name
      }
    };

    const res: Axios.AxiosResponse = await this.makeSecureCall(apiConfig);

    return res.data.data as CodatConsentResult;
  }
}
