import { DataRequest, DataRequestType } from '@cito/cp-data';
import { AuthService } from '@/services';
import { sentry } from '../../utils';

export class Auth {
  private _isAuthenticated = false;
  private _dataRequest: DataRequest | null = null;
  private _token = '';
  private _loading = false;
  private _challengeId = '';
  private _mobileSuffix = '';
  private _challengeName = '';
  private _type?: DataRequestType;
  private svc = new AuthService();
  private useSentry = process.env.VUE_APP_SENTRY_ACTIVE === 'true';

  public get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  public get isChallenged(): boolean {
    return this._challengeId !== '';
  }

  public get mobileSuffix(): string {
    return this._mobileSuffix;
  }

  public get challengeName(): string {
    return this._challengeName;
  }

  public get dataRequest(): DataRequest | null {
    return this._dataRequest;
  }

  public get token(): string {
    return this._token;
  }

  public get type(): DataRequestType | undefined {
    return this._type;
  }

  public get loading(): boolean {
    return this._loading;
  }

  public async loadTheme(requestId: string): Promise<void> {
    this._loading = true;

    const theme = await this.svc.loadTheme(requestId);
    this.setTheme(theme);

    this._loading = false;
  }

  public async loadType(requestId: string): Promise<void> {
    this._loading = true;

    this._type = await this.svc.loadType(requestId);

    this._loading = false;
  }

  public async login(email: string, requestId: string): Promise<void> {
    this._loading = true;

    this.clear();
    this.clearChallenge();
    this.saveToStore();

    const ret: any = await this.svc.login(email, requestId);
    if (ret) {
      this._challengeId = ret.challengeId ? ret.challengeId : '';
      this._challengeName = ret.name ? ret.name : '';
      this._mobileSuffix = ret.mobileSuffix ? ret.mobileSuffix : '???';
    }

    this._loading = false;
  }

  public async loginWithChallenge(email: string, requestId: string, otp: string): Promise<void> {
    this._loading = true;

    const ret = await this.svc.loginWithChallenge(email, requestId, otp, this._challengeId);
    if (ret && ret.dataRequest && ret.token) {
      this.clearChallenge();
      this._isAuthenticated = true;
      this._dataRequest = ret.dataRequest;
      this._token = ret.token;
      if ((ret as any).sessionId) {
        sentry.addSessionIdTag(this.useSentry, (ret as any).sessionId);
      }
    } else {
      this.clear();
    }

    this.saveToStore();

    this._loading = false;
  }

  public logout(): void {
    this.clear();
    this.clearChallenge();
    this.saveToStore();
  }

  private clear(): void {
    this._isAuthenticated = false;
    this._dataRequest = null;
    this._token = '';
  }

  private clearChallenge(): void {
    this._challengeId = '';
    this._challengeName = '';
    this._mobileSuffix = '';
  }

  public async loadFromStore(): Promise<void> {
    const token = localStorage.getItem('token');
    const requestId = this.parseIdFromURI();
    this._token = token ? token : '';

    await this.loadTheme(requestId);
    await this.loadType(requestId);
    await this.validateStore(requestId);
  }

  private saveToStore(): void {
    localStorage.setItem('token', this._token);
  }

  private clearStoredTheme(): void {
    localStorage.removeItem('theme');
    localStorage.removeItem('image');
    localStorage.removeItem('color');
  }

  private async validateStore(requestId: string): Promise<void> {
    this._loading = true;
    const ret = this._token ? await this.svc.validate(this._token, requestId) : undefined;
    if (ret) {
      this._isAuthenticated = true;
      this._dataRequest = ret;
    } else {
      this.clear();
      this.clearChallenge();
    }
    this._loading = false;
  }

  private parseIdFromURI(): string {
    try {
      const parser = document.createElement('a');
      parser.href = window.location.href;

      const path = parser.pathname;
      return path.split('/')[1];
    } catch (err) {
      return '';
    }
  }

  public async loadingPromise(): Promise<void> {
    while (this.loading) {
      await new Promise((resolve) => setTimeout(resolve, 10));
    }
    return;
  }

  private setTheme(themeParam: any) {
    const brand = typeof themeParam === 'object' ? themeParam : null;
    const theme = typeof themeParam === 'string' ? themeParam : null;

    this.clearStoredTheme();
    if (brand && brand.image) {
      localStorage.setItem('image', brand.image);
      localStorage.setItem('color', brand.color ? brand.color : '#000008c');
    } else if (theme) {
      // Keep using the old theme until we can transition to the object
      localStorage.setItem('theme', theme);
      if (theme === 'default') {
        localStorage.setItem('image', 'default');
      } // Make sure the Cito logo is used for default
      document.documentElement.dataset.theme = theme;
      localStorage.setItem('color', '#000008c');
    } else {
      localStorage.setItem('image', 'default');
      localStorage.setItem('color', brand?.color ? brand.color : '#000008c');
    }
  }
}
