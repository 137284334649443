<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-footer">
    <div class="container-fluid">
      <div class="navbar-brand footer mx-auto d-flex">
        <span class="my-auto">Powered by</span>
        <img class="my-auto ms-2 mb-1" src="/images/logo.png" height="15" />
      </div>
    </div>
  </nav>
</template>

<style scoped lang="scss">
.footer {
  font-size: 0.8em;
}
</style>
